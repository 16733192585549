import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import { Provider, useDispatch, useSelector } from 'react-redux'
import { store } from './services/store'
import Agents from "./components/Agents";
import Crops from "./components/Crops";
import Dashboard from "./components/Dashboard";
import Farmers from "./components/Farmers";
import Farms from "./components/FarmerFarms";
import Partners from "./components/Partners";
import Contents from "./components/Contents";
import Practices from "./components/Practices";
import PracticeDetails from "./components/Practices/PracticeDetails";
import FarmerDetails from "./components/Farmers/FarmerDetails";
import PartnerDetails from "./components/Partners/PartnerDetails";
import AgentDetails from "./components/Agents/AgentDetails";
import CropDetails from "./components/Crops/CropDetails";
import FileDetails from "./components/Files/FileDetails";
import ContentDetails from "./components/Contents/ContentDetails";
import Files from "./components/Files";
import FarmDetails from "./components/Farms/FarmDetails";
import Login from "./components/Auth/Login";
import { useEffect } from "react";
import { selectUser, setUser } from "./services/store/auth";

const AppWrapper = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector(selectUser)
  useEffect(() => {
    const userFromStorage = localStorage.getItem('currentUser')
    dispatch(setUser(JSON.parse(userFromStorage)))
  }, [dispatch])
  
  useEffect(() => {
  }, [currentUser])

  return (
    <Router>
      <Routes>
        {
          currentUser ?
          <>
            <Route path="/" element={<Dashboard/>} />
            <Route path="/partners" element={<Partners/>} />
            <Route path="/partners/:partnerId" element={<PartnerDetails/>} exact={true} />
            <Route path="/farmers" element={<Farmers/>} />
            <Route path="/farmers/:farmerId" element={<FarmerDetails/>} exact={true} />
            <Route path="/farms" element={<Farms/>} />
            <Route path="/farms/:farmId" element={<FarmDetails />} />
            <Route path="/agents" element={<Agents/>} />
            <Route path="/agents/:agentId" element={<AgentDetails/>} exact={true} />
            <Route path="/crops" element={<Crops/>} />
            <Route path="/crops/:cropId" element={<CropDetails/>} exact={true} />
            <Route path="/contents" element={<Contents/>} />
            <Route path="/contents/:contentId" element={<ContentDetails/>} exact={true} />
            <Route path="/files" element={<Files/>} />
            <Route path="/files/:fileId" element={<FileDetails/>} exact={true} />
            <Route path="/best-practices" element={<Practices/>} exact={true} />
            <Route path="/best-practices/:practiceId" element={<PracticeDetails/>} exact={true} />
          </> 
          :
          <>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
          </>
        }
      </Routes>
    </Router>
  );
}

function App() {
  return (
    <Provider store={store}>
      <AppWrapper />
    </Provider>
  );
}

export default App;
