import { camelizeKeys } from 'humps'
import axios from './http'

const getDefaultOptions = async () => {
  
  const defaultOptions = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }
  
  const token = window.localStorage.getItem('apiToken')
  if (token) {
    defaultOptions.headers['Authorization'] = `Bearer ${token}`
  }

  return defaultOptions
}

const handleRequestFailure = (response) => {
  console.error("The Request Failed", { response })
}

export async function get(url, options) {
  try {
    const defaultOptions = await getDefaultOptions()
    const { data } = await axios.get(url, {
      ...defaultOptions,
      ...options
    });
    return camelizeKeys(data)
  } catch (err) {
    handleRequestFailure(err)
  }
}

export async function post(url, body, options) {
  try {
    const defaultOptions = await getDefaultOptions()
    const { data } = await axios.post(url, body, {
      ...defaultOptions,
      ...options
    })
    return camelizeKeys(data)
  } catch (err) {
    handleRequestFailure(err)
  }
}

export async function patch(url, body, options) {
  try {
    const defaultOptions = await getDefaultOptions()
    const { data } = await axios.patch(url, body, {
      ...defaultOptions,
      ...options
    });
    return camelizeKeys(data)
  } catch (err) {
    handleRequestFailure(err)
  }
}

export async function destroy(url, options) {
  try {
    const defaultOptions = await getDefaultOptions()
    return await axios.delete(url, {
      ...defaultOptions,
      ...options
    });
  } catch (err) {
    handleRequestFailure(err)
  }
}
