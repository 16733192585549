import { HomeIcon, UserGroupIcon, BookOpenIcon, GlobeIcon, ClipboardListIcon, SunIcon } from "@heroicons/react/outline";

const navigation = [
  { name: "Home", href: "/", icon: HomeIcon, current: true },
  { name: "Farmers", href: "/farmers", icon: UserGroupIcon, current: false },
  { name: "Farms", href: "/farms", icon: GlobeIcon, current: false },
  { name: "Partners", href: "/partners", icon: UserGroupIcon, current: false },
  { name: "Agents", href: "/agents", icon: UserGroupIcon, current: false },
  { name: "Crops", href: "/crops", icon: SunIcon, current: false },
  { name: "Files", href: "/files", icon: BookOpenIcon, current: false },
  { name: "Contents", href: "/contents", icon: BookOpenIcon, current: false },
  { name: "Best Practices", href: "/best-practices", icon: ClipboardListIcon, current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Sidebar({ selected = "Home" }) {
  

  return (
    <>
      <div>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 bg-white overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <h3 className="text-gray-900 text-xl font-bold">
                Ndobai Admin
              </h3>
            </div>
            <div className="mt-5 flex-grow flex flex-col">
              <nav className="flex-1 px-2 pb-4 space-y-1">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      selected.toLocaleLowerCase() === item.name.toLowerCase()
                        ? "bg-gray-100 text-gray-900"
                        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        selected.toLocaleLowerCase() === item.name.toLowerCase()
                          ? "text-gray-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
