import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import NewFarm from "./NewFarm";
import { getFarms } from '../services/api/farms';

export default function Farms() {
  const [farms, setFarms] = useState([])

  const latLonValue = (location) => {
    try {
      location = JSON.parse(location)
      return [location.lat, location.lon].join(",")
    } catch {
      return location
    }
  }

  useEffect(() => {
    async function refreshFarms() {
      const { data: result } = await getFarms()
      setFarms(result)
    }
    refreshFarms()
  }, [])

  async function searchForFarms(search) {
    const { data: result } = await getFarms({
      params: {
        q:  search
      }
    })
    setFarms(result)
  }
  
  return (
    <>
      <div>
        <Sidebar selected="Farms" />
        <div>
          <Topbar
            onSearchUpdated={(s) => searchForFarms(s)}
          />
          <main className="md:pl-64 flex flex-col flex-1 px-4 sm:px-6">
            <div className="pt-4 ml-4 sm:flex sm:items-center">
              <div className="sm:flex-auto ">
                <h1 className="text-2xl font-semibold text-gray-900">
                  Farms
                </h1>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <NewFarm/>
              </div>
            </div>
            <div className="px-4 mt-8 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Farmer
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Location
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Crops
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Added At
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Last Updated At
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                          >
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {farms.map((farm) => (
                          <tr key={farm.id}>
                            <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                              <a
                                href={`/farmers/${farm.farmer?.id}`}
                              >
                                {farm.farmer?.name}
                              </a>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <a
                                href={`https://maps.google.com?q=${latLonValue(farm.gpsLocation)}`}
                                target="_blank"
                                rel="noreferrer"
                                className="underline font-semibold text-gray-800"
                              >
                                {latLonValue(farm.gpsLocation)}
                              </a>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {farm.crops.map(crop => crop.name).join(", ")}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            { dayjs(farm.createdAt).format('YYYY-MM-DD h:mm') }
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            { dayjs(farm.updatedAt).format('YYYY-MM-DD h:mm') }
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <a
                                href={`/farms/${farm.id}`}
                                className="text-indigo-600 hover:text-indigo-900 mr-3"
                              >
                                View
                              </a>
                              <a
                                href="/#"
                                className="text-indigo-600 hover:text-indigo-900"
                              >
                                Edit
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}