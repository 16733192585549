
import { useEffect, useState } from "react";
import { getStatsSummary } from "../services/api/stats";
import Stats from "./Dashboard/Stats";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";

export default function Dashboard() {
  const [stats, setStats] = useState([])

  useEffect(() => {
    async function refreshStats () {
      const { data: summary } = await getStatsSummary()
      setStats(summary)
    }

    refreshStats()
  }, [])
  
  return (
    <div>
      <Sidebar selected="Dashboard" />
      <div>
        <Topbar/>
        <main className="md:pl-64 flex flex-col flex-1">
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <div className="py-4">
                <Stats
                  stats={stats}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}