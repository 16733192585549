import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import NewSchedule from "./NewSchedule";
import { getPracticeSchedules } from '../../services/api/schedules';
import { useParams } from "react-router-dom";

export default function Schedules() {
  const { practiceId } = useParams()

  const [schedules, setSchedules] = useState([])

  useEffect(() => {
    async function refreshPractices() {
      const { data: result } = await getPracticeSchedules(practiceId)
      setSchedules(result)
    }
    refreshPractices()
  }, [practiceId])
  
  return (
    <>
      <div>
        <main className="flex flex-col flex-1">
          <div className="pt-4 sm:flex sm:items-center">
            <div className="sm:flex-auto ">
              <h1 className="text-2xl font-semibold text-gray-900">
                Schedules
              </h1>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <NewSchedule/>
            </div>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Content
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Added At
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Last Updated At
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {schedules.map((schedule) => (
                        <tr key={schedule.id}>
                          <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                            <a
                              href={`/best-practices/${practiceId}/schedules/${schedule.id}`}
                            >
                              {schedule.content?.title}
                            </a>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            { dayjs(schedule.createdAt).format('YYYY-MM-DD HH:mm') }
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            { dayjs(schedule.updatedAt).format('YYYY-MM-DD HH:mm') }
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <a
                              href="/#"
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              Edit
                              <span className="sr-only">, {schedule.title}</span>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}