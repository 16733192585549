import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { postFarmers } from "../services/api/farmers";
const regions = require("../services/data/regions.json");
const regionNames = Object.keys(regions);

export default function NewFarmer() {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState("");
  const [gender, setGender] = useState("");
  const [education, setEducation] = useState("");
  const [occupation, setOccupation] = useState("");
  const [language, setLanguage] = useState("");
  const [disabilities, setDisabilities] = useState("")
  const [town, setTown] = useState("");
  const [district, setDistrict] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [districtsInRegion, setDistrictsInRegion] = useState([])

  useEffect(() => {
    if (selectedRegion) {
      setDistrictsInRegion(regions[selectedRegion])
    } else {
      setDistrictsInRegion([])
    }
  }, [selectedRegion])

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!name || !phone) {
      alert("Please correct the form before continuing");
      return;
    }
    if (phone.length < 9 || phone.length > 13) {
      alert("A valid phone number must between 9 and 13 characters")
      return
    }

    const data = {
      name,
      phone_number: phone,
      date_of_birth: date,
      gender,
      educational_level: education,
      preferred_language: language,
      other_occupation: occupation,
      disabilities,
      town,
      district,
      region: selectedRegion,
    };

    const { success, message } = await postFarmers(data);

    if (!success) {
      alert("Saving this farmer failed. Kindly refresh and try again.");
      return;
    }

    alert(message);
    window.location.reload();
  };

  return (
    <>
      <div>
        <button
          type="button"
          className="inline-flex items-center justify-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          onClick={() => setOpen(true)}
        >
          Create Farmer
        </button>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form
                      onSubmit={handleFormSubmit}
                      action="POST"
                      className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                    >
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-50 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-lg font-medium text-gray-900">
                                New farmer
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">
                                Create a new farmer.
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* Divider container */}
                        <div className="space-y-6 py-6 sm:divide-gray-200 sm:py-0">
                          {/* farmer name */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="farmer-name"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Farmer name
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="farmer-name"
                                id="farmer-name"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                          </div>
                          {/* Farmer phone number */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="farmer-phone"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Phone Number
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="farmer-phone"
                                id="farmer-phone"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                              />
                            </div>
                          </div>
                          {/* Farmer Date of birth */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="date-of-birth"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Date Of Birth
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <p className="block text-xs text-gray-900 sm:mt-px sm:pb-1">
                                Use 1st January of the year, if the farmer does not know their exact date of birth.
                              </p>
                              <input
                                type="date"
                                name="date-of-birth"
                                id="date-of-birth"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                              />
                            </div>
                          </div>

                          {/* Gender */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="gender"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Gender
                              </label>
                            </div>
                            <select
                              value={gender}
                              className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              onChange={(e) => setGender(e.target.value)}
                            >
                              <option value="">Select gender</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </select>
                          </div>

                          {/* Level Of Education */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="level-of-education"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Level Of Education
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="level-of-education"
                                id="level-of-education"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={education}
                                onChange={(e) => setEducation(e.target.value)}
                              />
                            </div>
                          </div>

                          {/* Disabilities */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="disabilities"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Disabilities
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="disabilities"
                                id="disabilities"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={disabilities}
                                onChange={(e) => setDisabilities(e.target.value)}
                              />
                            </div>
                          </div>

                          {/* preferred language*/}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="language"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Preferred Language
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="language"
                                id="language"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={language}
                                onChange={(e) => setLanguage(e.target.value)}
                              />
                            </div>
                          </div>

                          {/* other employment/occupation */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="occupation"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Other Occupation
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="occupation"
                                id="occupation"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={occupation}
                                onChange={(e) => setOccupation(e.target.value)}
                              />
                            </div>
                          </div>

                          {/* town */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="town"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Town
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="town"
                                id="town"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={town}
                                onChange={(e) => setTown(e.target.value)}
                              />
                            </div>
                          </div>


                          {/* Region */}
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="region"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Region
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <select
                                value={selectedRegion}
                                name="region"
                                id="region"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                onChange={(e) => setSelectedRegion(e.target.value)}
                              >
                                <option>Select region</option>
                                {regionNames.map((regionName, regIndex) => (
                                  <option key={regIndex} value={regionName}> {regionName} </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                       {/* district*/}
                       {
                         selectedRegion && districtsInRegion && districtsInRegion.length && (
                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                  <div>
                                    <label
                                      htmlFor="district"
                                      className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                    >
    
                                      District
                                    </label>
                                  </div>
                                  <div className="sm:col-span-2">
                                  <select
                                      value={district}
                                      name="district"
                                      id="district"
                                      className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                      onChange={(e) => setDistrict(e.target.value)}
                                    >
                                      <option>Select district</option>
                                      {districtsInRegion.map((district, indx) => (
                                        <option
                                          key={indx}
                                          value={district.District}
                                        >
                                          {district.District}
                                        </option>
                                      ))}
                                    
                                    </select>
                                  </div>
                            </div>
                         )
                       }

                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div className="flex justify-end space-x-3">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Create
                          </button>
                        </div>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
