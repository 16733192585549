import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      if (!action.payload) {
        localStorage.removeItem('currentUser')
      } else {
        localStorage.setItem('currentUser', JSON.stringify(action.payload))
      }
      state.user = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUser } = authSlice.actions

export const selectUser = (state) => {
  return state.auth?.user
}

export default authSlice.reducer
