import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { getPractices } from '../../services/api/practices';
import { getFarmPractices } from "../../services/api/farms";
import EmptyState from "../shared/EmptyState";
import NewFarmPractice from "../NewFarmPractice";

export default function PracticesList({ farmId = null, showNewButton = false, search = '' }) {
  const [practices, setPractices] = useState([])

  useEffect(() => {
    async function refreshPractices() {
      const { data: result } = farmId ? await getFarmPractices(farmId) : await getPractices()
      setPractices(result)
    }
    refreshPractices()
  }, [farmId])

  
  useEffect(() => {
    async function refreshPractices() {
      const options = {
        params: {
          q: search,
        }
      }
      const { data: result } = farmId ? 
        await getFarmPractices(farmId, options)
        : await getPractices(options)
      setPractices(result)
    }
    refreshPractices()

    return () => {
      setPractices([])
    }
  }, [farmId, search])
  
  return (
    <div className="flex flex-col">
      {
        !practices.length ? (
          <div className="my-12 mb-24">
            <EmptyState
              title="Agronomical Practices"
              showButton={false}
              onClick={() => {}}
            />
          </div>
        ) : (
          <>
            {
              showNewButton && (
                <div className="my-4 flex justify-end">
                  <NewFarmPractice />
                </div>
              )
            }
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Title
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Description
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Added At
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Last Updated At
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {practices.map((practice) => (
                        <tr key={practice.id}>
                          <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                            <a
                              href={`/best-practices/${practice.id}`}
                            >
                              {practice.title}
                            </a>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            { practice.description }
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            { dayjs(practice.createdAt).format('YYYY-MM-DD HH:mm') }
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          { dayjs(practice.updatedAt).format('YYYY-MM-DD HH:mm') }
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <a
                              href="/#"
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              Edit
                              <span className="sr-only">, {practice.title}</span>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )
      }
    </div>
  );
}
