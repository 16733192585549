import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { getFarm } from "../../services/api/farms";
import PracticesList from "../Practices/PracticesList";
import NewFarmPractice from "../NewFarmPractice";
import EmptyState from "../shared/EmptyState";

const latLonValue = (location) => {
  try {
    location = JSON.parse(location)
    return [location.lat, location.lon].join(",")
  } catch {
    return location
  }
}

export default function FarmDetails() {
  const { farmId } = useParams();
  const [farm, setFarm] = useState(null)

  useEffect(() => {
    async function refreshFarm() {
      const { data: result } = await getFarm(farmId)
      setFarm(result)
    }
    refreshFarm()
  }, [farmId])
  
  return (
    <>
      <div>
        <Sidebar selected="Farms" />
        <div>
          <Topbar />
          <main className="md:pl-64 flex flex-col flex-1 px-4 sm:px-6">
            {
              farm && (
                <div className="py-6">
                  <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <h1 className="text-2xl font-semibold text-gray-900">
                      Farm by: {farm?.farmer?.name}
                    </h1>
                  </div>
                  <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                    <div className="py-4">
                      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                            <div className="sm:col-span-2">
                              <dt className="text-sm font-medium text-gray-500">Farmer</dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                <a
                                  href={`/farmers/${farm.farmer?.id}`}
                                  className="text-sm font-medium text-gray-900 underline"
                                >
                                  {farm.farmer?.name}
                                </a>
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">GPS Location</dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                <a
                                  href={`https://maps.google.com?q=${latLonValue(farm.gpsLocation)}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="underline font-semibold text-gray-800"
                                >
                                  {latLonValue(farm.gpsLocation)}
                                </a>
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">Town</dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                { farm.town}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">District</dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                { farm.district}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">Region</dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                { farm.region}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">Created At</dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                { dayjs(farm.updatedAt).format('YYYY-MM-DD HH:mm') }
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">Last Updated At</dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                { dayjs(farm.updatedAt).format('YYYY-MM-DD HH:mm') }
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">Deleted At</dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                { farm.deletedAt ? dayjs(farm.deletedAt).format('YYYY-MM-DD HH:mm') : '-'}
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>

                      <div className="mt-8">
                        <main className="flex flex-col flex-1">
                          <div className="sm:flex sm:items-center mb-2">
                            <div className="sm:flex-auto ">
                              <h1 className="text-2xl font-semibold text-gray-900">
                                Crops Under Cultivation
                              </h1>
                            </div>
                            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                              {/* <NewFarmPractice /> */}
                            </div>
                          </div>
                          <div className="flex flex-col">
                            {
                              !farm?.crops.length ? (
                                <div className="my-12 mb-24">
                                  <EmptyState
                                    title="Crops Under Cultivation"
                                    showButton={false}
                                    onClick={() => {}}
                                  />
                                </div>
                              ) : (
                                <>
                                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-300">
                                          <thead className="bg-gray-50">
                                            <tr>
                                              <th
                                                scope="col"
                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                              >
                                                Name of Crop
                                              </th>
                                              <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                              >
                                                Crop Capacity
                                              </th>
                                              <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                              >
                                                Last Updated At
                                              </th>
                                              <th
                                                scope="col"
                                                className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                              >
                                                <span className="sr-only">Edit</span>
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody className="divide-y divide-gray-200 bg-white">
                                            {farm?.crops?.map((crop) => (
                                              <tr key={crop.id}>
                                                <td className="whitespace-nowrap pl-4 sm:pl-6 pr-3 py-4 text-sm font-medium text-gray-900">
                                                  <span>
                                                    {crop.name}
                                                  </span>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                  { crop?.pivot?.cropCapacity || 'N/A' }
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                { dayjs(crop.updatedAt).format('YYYY-MM-DD HH:mm') }
                                                </td>
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                  <a
                                                    href="/#"
                                                    className="text-indigo-600 hover:text-indigo-900"
                                                  >
                                                    Edit
                                                    <span className="sr-only">, {crop.name}</span>
                                                  </a>
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                            }
                          </div>
                        </main>
                      </div>

                      <div className="mt-8">
                        <main className="flex flex-col flex-1">
                          <div className="sm:flex sm:items-center mb-2">
                            <div className="sm:flex-auto ">
                              <h1 className="text-2xl font-semibold text-gray-900">
                                Best Practices
                              </h1>
                            </div>
                            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                              <NewFarmPractice />
                            </div>
                          </div>
                          <PracticesList
                            farmId={farmId}
                            showNewButton={false}
                          />
                        </main>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </main>
        </div>
      </div>
    </>
  );
}
