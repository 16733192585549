import { get, post } from './requests'
import { API_BASE_URL, BASE_URL } from '../constants'

export const getSessionCookies = async (options = {}) => {
  return get(`${BASE_URL}/sanctum/csrf-cookie`, options)
}

export const postLogin = async (email, password) => {
  return post(`${BASE_URL}/login`, {
    email,
    password
  })
}

export const getCurrentUser = async (options = {}) => {
  return get(`${API_BASE_URL}/user`, options)
}

export const postLogout = async () => {
  return post(`${BASE_URL}/logout`)
}
