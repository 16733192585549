import { API_BASE_URL } from '../constants'
import { get, patch, post, destroy } from './requests'

export const getFarmers = async (options = {}) => {
  return await get(`${API_BASE_URL}/farmers`, options)
}

export const getFarmer = async (farmerId, options = {}) => {
  return await get(`${API_BASE_URL}/farmers/${farmerId}`, options)
}

export const postFarmers = async (body, options = {}) => {
  return await post(`${API_BASE_URL}/farmers`, body, options)
}

export const postFarmersBulkUploads = async (body, options = {}) => {
  return await post(`${API_BASE_URL}/farmers/bulk-uploads`, body, options)
}

export const patchFarmer = async (farmerId, body, options = {}) => {
  return await patch(`${API_BASE_URL}/farmers/${farmerId}`, body, options)
}

export const deleteFarmer = async (farmerId, options = {}) => {
  return await destroy(`${API_BASE_URL}/farmers/${farmerId}`, options)
}
