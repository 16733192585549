import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { postFarmerDevices } from "../services/api/farmerDevices";

export default function NewFarmerDevice({ defaultState = false, showButton = true, onClose = () => {}, farmer = {} }) {
  const [open, setOpen] = useState(defaultState);
  const [farmerId, setFarmerId] = useState(farmer.id);
  const [deviceName, setDeviceName] = useState('')

  const isValidForm = () => {
    if (!farmerId) { return false }
    if (!deviceName) { return false }
    return true
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()

    if (!isValidForm()) {
      alert("Please correct the form before continuing")
      return
    }

    const data = {
      farmer_id: farmerId,
      device_name: deviceName,
    }

    const { success, message } = await postFarmerDevices(data)

    if (!success) {
      alert("Saving this device failed. Kindly refresh and try again.")
      return
    }

    alert(message)
    window.location.reload()
  }

  useEffect(() => {
    if (farmer && farmer.id) {
      setFarmerId(farmer.id)
    } else {
      setFarmerId("")
    }
  }, [farmer])

  useEffect(() => {
    setOpen(defaultState)
  }, [defaultState])

  useEffect(() => {
    if (!open) {
      onClose()
    }
  }, [open, onClose])

  return (
    <>
      <div>
        {
          !!(showButton) && (
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              onClick={() => setOpen(true)}
            >
              Add a new Farmer Device
            </button>
          )
        }
      </div>
      
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form 
                      onSubmit={handleFormSubmit}
                      action="POST"
                      className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                    >
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-50 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-lg font-medium text-gray-900">
                                New Farmer Device
                              </Dialog.Title>
                              <p className="text-sm text-gray-500">
                                Add a new farmer device.
                              </p>
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                          {/* Farmer */}
                          <div className="space-y-1 px-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div className="mb-4">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">Farmer</h3>
                            </div>
                            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                              <div className="sm:col-span-3">
                                <div className="mt-1">
                                  {
                                    farmer && (
                                      <>
                                        <p>
                                          <span
                                            target="_blank"
                                            rel="noreferrer"
                                            className="underline font-semibold text-gray-800"
                                          >
                                            {farmer.name}
                                          </span>
                                        </p>
                                        <p>{farmer?.gender}</p>
                                        <p>{farmer?.phoneNumber}</p>
                                        <p>{farmer?.town}</p>
                                        <p>{farmer?.district}</p>
                                        <p>{farmer?.region}</p>
                                      </>
                                    )
                                  }
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                htmlFor="device-name"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Device Name
                              </label>
                            </div>
                            <div className="sm:col-span-2">
                              <input
                                type="text"
                                name="device-name"
                                id="device-name"
                                className=" w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={deviceName}
                                onChange={(e) => setDeviceName(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div className="flex justify-end space-x-3">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={!isValidForm()}
                            type="submit"
                            className={
                              `inline-flex justify-center rounded-md border border-transparent  py-2 px-4 
                              text-sm font-medium  shadow-sm  focus:outline-none focus:ring-2  focus:ring-offset-2
                              ${!isValidForm() ? 
                                'bg-gray-300 text-gray-600' : 
                                'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 text-white'
                              }`
                            }
                          >
                            Create
                          </button>
                        </div>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
