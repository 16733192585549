import React, { useEffect, useState } from "react";
import dayjs from 'dayjs';
import NewPartner from "./NewPartner";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { getPartners } from "../services/api/partners";

export default function Partners() {
  const [partners, setPartners] = useState([])

  useEffect(() => {
    // const { data: result } = await getPartners()
    // setPartners(result)
    async function refreshPartners() {
      const { data: result } = await getPartners()
      setPartners(result)
    }
    refreshPartners()
  }, [])

  async function searchForPartners(search) {
    const { data: result } = await getPartners({
      params: {
        q:  search
      }
    })
    setPartners(result)
  }
  
  return (
    <>
      <div>
        <Sidebar selected="Partners" />
        <div>
        <Topbar
            onSearchUpdated={(s) => searchForPartners(s)}
          />
          <main className="md:pl-64 flex flex-col flex-1 px-4 sm:px-6">
            <div className="pt-4 ml-4 sm:flex sm:items-center">
              <div className="sm:flex-auto ">
                <h1 className="text-2xl font-semibold text-gray-900">
                  Partners
                </h1>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <NewPartner />
              </div>
            </div>
            <div className="px-4 mt-8 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Phone Number
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Added On
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Last Updated At
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                          >
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {partners.map((partner) => (
                          <tr key={partner.id}>
                            <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                             <a
                                href={`/partners/${partner.id}`}
                              >
                                {partner.name}
                              </a>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {partner.phoneNumber}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              { dayjs(partner.createdAt).format('YYYY-MM-DD HH:mm') }
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            { dayjs(partner.updatedAt).format('YYYY-MM-DD HH:mm') }
                            </td>
                           
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <a
                                href="/#"
                                className="text-indigo-600 hover:text-indigo-900"
                              >
                                Edit
                                <span className="sr-only">, {partner.name}</span>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
